.svgBody {
  fill: #0f75ef;
}

.size-large {
  width: 570px;
  height: 570px;
}

.size-medium {
  width: 200px;
  height: 200px;
}

.size-small {
  width: 50px;
  height: 50px;
}

.position-centered {
  position: absolute;
  margin:  auto auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.scheme-blue {
  fill: #0f75ef;
}

.scheme-white {
  fill:  white;
}

.scheme-black {
  fill:  black;
}

.svgBody {
  animation: appear 1500ms;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}