/* FONTS */
@font-face {
  font-family: 'Courier';
  src: 
      /*url('../../assets/fonts/courier/courier-webfont.woff2') format('woff2'),*/
       url('../../assets/fonts/courier/courier-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/*@font-face {
  font-family: 'tnmedium';
  src: 
      url('../../assets/fonts/tn-medium/tn-medium-webfont.woff2') format('woff2'),
       url('../../assets/fonts/tn-medium/tn-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}*/

@font-face {
  font-family: 'tnlight';
  src: 
      /*url('../../assets/fonts/tn-light/tn-light-webfont.woff2') format('woff2'),*/
       url('../../assets/fonts/tn-light/tn-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}